import {
	Box,
	Button,
	Divider,
	InputAdornment,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	MenuItem,
	Popover,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { GroupWork, Search } from '@mui/icons-material';
const GroupRestaurants = ({
	groups,
	disabled,
	handleSubmit,
	variant = 'button',
	restaurant = null,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [searchTerm, setSearchTerm] = useState('');
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const filterBySearch = (group) => {
		return (
			searchTerm === '' ||
			group.groupName?.toLowerCase().includes(searchTerm?.toLowerCase())
		);
	};

	const groupClicked = (groupId) => {
		handleSubmit(groupId, restaurant);
		handleClose();
	};
	const PopoverSection = () => {
		return (
			<Popover
				id='simple-popover'
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose}
				disableScrollLock={true}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<Stack sx={{ p: 2, width: 308, height: 256 }}>
					<Typography sx={{ fontSize: '16px' }}>Group to:</Typography>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							backgroundColor: '#FFFFFF',
						}}>
						<TextField
							margin='dense'
							variant='standard'
							placeholder='Search group'
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Search sx={{ fontSize: 24 }} />
									</InputAdornment>
								),
							}}
						/>
					</Box>
					<List sx={{ maxHeight: 300, overflow: 'auto' }}>
						{groups?.filter(filterBySearch).map((group) => (
							<ListItem disablePadding key={group.groupId}>
								<ListItemButton onClick={() => groupClicked(group.groupId)}>
									<ListItemText>
										<Typography sx={{ fontSize: '14px' }}>
											{group.groupName}
										</Typography>
									</ListItemText>
								</ListItemButton>
							</ListItem>
						))}
					</List>
				</Stack>
			</Popover>
		);
	};

	return variant === 'button' ? (
		<div>
			<Button
				variant='text'
				startIcon={<GroupWork sx={{ color: '#718EBF', fontSize: 20 }} />} // Different icon and softer blue
				sx={{ fontSize: '12px', textTransform: 'none' }}
				onClick={handleClick}
				disabled={disabled}>
				Group selected restaurants
			</Button>
			<PopoverSection />
		</div>
	) : (
		<div>
			<MenuItem
				variant='text'
				sx={{ fontSize: '12px', textTransform: 'none' }}
				onClick={handleClick}>
				Add To Group
			</MenuItem>
			<PopoverSection />
		</div>
	);
};

export default GroupRestaurants;
